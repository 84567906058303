.mainContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 2em;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1em;
  column-gap: 0.5em;
}

.pricingColumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  row-gap: 1.5em;
}

.item {
  display: flex;
  flex-direction: column;
  row-gap: 0.25em;
}

.lightText {
  font-family: "proxima_novaregular";
}

.header {
  composes: flex-row from "../../index.module.scss";
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header .shipDateColumn {
  margin-top: 1em;
}

.backButton {
  border: 0 none;
  color: rgb(31, 118, 220);
  text-transform: uppercase;
  cursor: pointer;
  padding-top: 2em;
  padding-bottom: 1em;
  display: flex;
}

.backButton:hover {
  filter: brightness(85%);
}

.backButton > span {
  font-size: 1.3em;
  margin-top: 1px;
  vertical-align: bottom;
}

.statusButton {
  background-color: #fff;
  margin: 1em 1em 1em 0;
  border-color: rgb(31, 118, 220);
  color: #5e5d5e;
}

.submitButton {
  margin: 1em 0 1em 0;
  background-color: #a8a9ae;
  border-color: #a8a9ae;
  color: #fff;
}

.submitButton,
.statusButton {
  border-style: solid;
  border-width: 2px;
  text-transform: uppercase;
  padding: 8px 24px;
}

.statusText {
  color: #5e5d5e !important;
}

.orderInfoContainer {
  align-items: center;
  margin: 0.5em 0 1.5em;
  padding: 0 0 0 1.75em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 1.25em;
}

.linksWrapper {
  display: flex;
  flex-direction: row;
  column-gap: 0.75em;
  align-items: center;
  color: #5e5d5e;
  font-size: 1.1em;
  cursor: pointer;
}

.orderInfoText {
  font-size: 1.75em;
  font-weight: bold;
  text-transform: uppercase;
  color: #5e5d5e;
}

.orderNumber {
  font-size: 1.75em;
  font-weight: bold;
}

.productsContainer {
  position: relative;
  box-shadow: 0 2px 10px rgb(72 73 73 / 20%);
  border-radius: 6px;
  margin-bottom: 2.5em;
  background: #ffffff;
}

.lowDpiIndicator {
  background-color: red;
  border-radius: 50%;
  color: white;
  position: absolute;
  top: -6px;
  left: -8px;
  min-width: 25px;
  min-height: 25px;
}

.lowDpiIndicator .iconAlert {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  margin-top: 5px;
}

.orderNumber a {
  color: #1f76dc;
}

.orderStatus {
  margin-top: 4px;
  color: #1f76dc;
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
  vertical-align: baseline;
}

.mStatus {
  margin-top: 4px;
  color: #fb9131;
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
  vertical-align: baseline;
}

.customerContainer {
  margin: 1em 0 2em;
  box-shadow: 0 2px 10px rgb(72 73 73 / 20%);
  border-radius: 6px;
  padding: 1em 2em;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background: #ffffff;
}
.column {
  display: flex;
  flex-direction: row;
}

.labelGroup {
  text-align: right;
  color: #5e5d5e;
  padding-right: 0.75em;
}

.infoGroup {
  color: #1f76dc;
  padding-right: 1.25em;
}

.loadingText {
  margin: 2em;
  font-weight: bold;
  text-transform: uppercase;
  color: #5e5d5e;
  font-size: 1.5em;
}

.priceContainter {
  margin: 1em 0 2em;
  box-shadow: 0 2px 10px rgb(72 73 73 / 20%);
  border-radius: 6px;
  padding: 1em 2em;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background: #ffffff;
}

.priceContainter .text {
  color: #5e5d5e;
}

.priceContainter .partnerCost {
  color: #000000;
}

.priceContainter .totalPartnerCost {
  color: #02be02;
}

.prices {
  flex: 2;
  font-size: 1.2em;
  // margin: 0 1em 0 0;
  padding: 1em 0 1.5em;
  border-bottom: 1px solid #ddd;
}

.prices i {
  padding-left: 0.5em;
  color: #5e5d5e;
}

.column > .btn {
  width: 185px;
}

.estimatedShipDate {
  text-transform: uppercase;
}

.estimatedShipDate > span {
  padding-left: 5px;
  color: red;
}

.orderInfoContainer .orderDataColumn {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  column-gap: 1em;
  flex-flow: row wrap;
}

.shipDateColumn {
  font-size: 1.2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  width: 100%;
  margin: 0 0 0.5em;
}

.shipDateColumn .quote {
  font-size: 0.8em !important;
  padding-right: 4em;
}

.priceContainter .priceDetails {
  display: flex;
  flex-direction: row;
  column-gap: 2em;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-grow: 4;
}

.priceContainter .priceButton {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0.5em;
}

.priceContainter .priceButtonWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 1em 0.5em;
  padding-top: 1em;
  border-top: 1px solid #ddd;
}

.priceDetails .taxes {
  text-transform: capitalize;
  font-size: 0.9em;
  color: #5e5d5e;
  margin: 0;
}

.priceDetails .emptyTaxes .taxes {
  margin: 1em;
}

.priceDetails .emptyTaxes > .text {
  padding-bottom: 0em !important;
}

.shipDateColumn .estimatedShipDate:nth-child(1) {
  padding-bottom: 0.25em;
}

.shipDateColumn .estimatedShipDate:nth-child(2) {
  border-top: 1px solid #ddd;
  padding-top: 0.25em;
}

.rushOptions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  column-gap: 1em;
}

.rushWrapper .prices {
  padding-bottom: 0;
  margin-bottom: 0.5em;
}

.shippingWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 1em 0 0;
  font-size: 1.2em;
  row-gap: 1em;
}

.shippingWrapper .column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 30 1 auto;
}

.shippingWrapper .shipTo {
  column-gap: 1em;
  display: flex;
  flex-direction: row;
}

.shippingWrapper .row:nth-child(1) {
  display: flex;
  justify-content: space-between;
}

.shippingWrapper .shiptoAddress {
  color: #5e5d5e;
}

.shippingWrapper .editShipTo {
  cursor: pointer;
  color: #1f76dc;
  display: flex;
  flex-direction: row;
}

.shippingWrapper .editShipTo span:nth-child(2) {
  text-decoration: underline;
}

.shippingWrapper .editShipTo:hover {
  filter: brightness(85%);
}

.shippingWrapper .editShipTo > span {
  font-size: 1em !important;
}

.shippingWrapper .pickupLocation {
  column-gap: 1em;
  display: flex;
}

.shippingWrapper .row {
  flex-wrap: unset !important;
}

.pickupLocation .store {
  color: #1f76dc;
  text-transform: uppercase;
}

.exclamationIcon {
  margin-bottom: 0.25em;
  font-size: 18px;
  color: #fd7e14;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  column-gap: 2.5em;
  align-items: center;
  justify-content: center;
}

.buttonsContainer div {
  width: 12em;
}

.disabledStore {
  color: #999 !important;
  text-decoration: line-through;
}

.mt0 {
  margin-top: 0px !important;
}

.shipToAddressContainer {
  margin: 0 0 0.75em;
  border-radius: 6px;
  padding: 1em 1.75em;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background: #1f76dc;
  width: 100%;
  color: #ffffff;
}

.shipToAddressContainer .shiptoAddress {
  color: #ffffff !important;
}

.shipToRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-column-gap: 0.5em;
  column-gap: 0.5em;
}

.dotWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #1f76dc;
}

.dot {
  border: 1.5px solid #1f76dc;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  margin-right: 5px;
}

.dot i {
  font-size: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3.5px;
}

.contactContainer {
  display: flex;
  column-gap: 2em;
}

.contactContainer .contactCard,
.contactContainer .contactAddressCard {
  flex: 1;
  border-radius: 6px;
  border: 1px solid #ddd;
  background-color: transparent !important;
  color: #5e5d5e;
  margin-bottom: 2em;
  animation: fadein 0.5s;
}

.contactTitle {
  flex: 1;
  justify-content: flex-start;
}

.flexRow {
  display: flex;
  flex-direction: row;
  column-gap: 2em;
  margin-bottom: 0.75em;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5em;
  height: 100%;
  color: #5e5d5e;
}

.btnContactWrapper {
  display: flex;
  flex-direction: row-reverse !important;
  justify-content: flex-end;
  align-items: center;
}

.reverseRow {
  display: flex;
  flex-direction: row-reverse;
  column-gap: 0.75em;
}

.reverseRow span {
  cursor: pointer;
}

.p05 {
  padding: 0 0.5em;
}

.orgName {
  padding-top: 0.25em;
  display: flex;
  flex-direction: row;
}

.contactCardInfo {
  display: flex;
  flex-direction: column;
  row-gap: 0.25em;
}

.contactCardInfo span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.emailAndPhone {
  display: flex;
  flex-wrap: wrap;
}

.padding5 {
  padding-left: 5px;
  padding-right: 5px;
}

.pt025 {
  padding-top: 0.25em;
}

.lineDivider {
  border-top: 1px solid #ddd;
  flex: 1;
}

.lineThrough {
  text-decoration: line-through;
  color: #999;
}

.uniqueRate {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.5em;
  font-size: 1.15em;
}

.uniqueRate > span {
  margin-top: 2px;
}

.displayRow {
  display: flex;
  flex-direction: row;
  column-gap: 0.25em;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 850px) {
  .rushWrapper {
    padding: 0 1em;
  }

  .shippingWrapper .row {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media only screen and (max-width: 1500px) {
  .rightColumn {
    flex: auto;
  }
}

@media (min-width: 850px) and (max-width: 1280px) {
  .orderInfoText,
  .orderNumber {
    font-size: 1.5em;
  }

  .orderStatus {
    text-align: center;
  }

  .orderInfoContainer {
    padding: 0;
  }

  .shipDateColumn {
    font-size: 1em;
  }

  .orderInfoContainer .orderDataColumn {
    flex-wrap: wrap;
  }
}

@media (min-width: 850px) and (max-width: 1024px) {
  .customer {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    padding-top: 1em;
  }
}

@media only screen and (max-width: 1275px) {
  .customerContainer {
    justify-content: space-between;
    flex-wrap: unset;
  }

  .priceContainter .priceDetails {
    flex-wrap: nowrap;
  }
}

@media only screen and (max-width: 1200px) {
  .contactCardInfo {
    display: flex;
    flex-direction: column;
    row-gap: 0.25em;
  }

  .emailAndPhone {
    display: flex;
    flex-direction: column;
    row-gap: 0.25em;
  }

  .contactCardInfo .p05 {
    display: none;
  }
}

@media only screen and (max-width: 1150px) {
  .customerContainer {
    justify-content: start;
    flex-wrap: wrap;
    row-gap: 1em;
  }

  .iconContainer i {
    padding: 0 0.5em;
  }
}

@media only screen and (max-width: 950px) {
  .orderInfoText,
  .orderNumber {
    font-size: 1.5em;
  }

  .orderStatus {
    text-align: center;
  }
}

@media only screen and (max-width: 900px) {
  .labelGroup {
    text-align: left;
    width: 25%;
  }

  .infoGroup {
    width: 75%;
  }

  .customerContainer {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 650px) {
  .orderInfoContainer {
    flex-direction: column;
    row-gap: 1.5em;
    padding: 0 1em;
    align-items: unset;
  }

  .linksWrapper {
    justify-content: flex-end;
  }

  .orderDataColumn {
    justify-content: flex-start;
  }

  .shipDateColumn {
    font-size: 1em;
  }

  .priceContainter .priceDetails {
    flex-direction: column;
    margin-top: 2em;
    align-items: start;
  }

  .customer {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    padding-top: 1em;
  }

  .customer div {
    padding-left: 0px;
    padding-right: 0px;
    text-align: center;
  }
}

@media only screen and (max-width: 550px) {
  .labelGroup {
    text-align: left;
    width: 35%;
  }

  .infoGroup {
    width: 65%;
  }

  .customerContainer {
    display: flex;
    flex-direction: column;
    padding: 1em;
  }

  .orderInfoText,
  .orderNumber {
    font-size: 1.25em !important;
  }

  .orderStatus {
    text-align: center;
  }
}

@media only screen and (max-width: 500px) {
  .statusButton,
  .submitButton {
    text-align: center;
    margin: 1em 3em 1em 3em;
  }

  .orderInfo {
    padding: 0;
    font-size: 0.9em;
    margin: 1em 0 1em 0;
  }

  .priceContainter {
    padding: 1em;
  }

  .shipToAddressContainer {
    padding: 1em;
  }

  .buttonsContainer {
    flex-direction: column;
    row-gap: 1em;
  }
}
