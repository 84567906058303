.wrapper {
  padding: 2em;
}

.wrapper svg {
  width: 75px;
  display: block;
  margin: 30px auto;
}

.wrapper .title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  color: #73AF55;
}

.wrapper .message {
  display: flex;
  flex-direction: column;
  line-height: 1.5em;
  padding: 2em 1em;
  color: #5e5d5e;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.wrapper .strong {
  color: black;
}

.wrapper .buttonConfirm {
  width: 12em;
}

.wrapper .button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  &.circle {
    -webkit-animation: dash .9s ease-in-out;
    animation: dash .9s ease-in-out;
  }
  &.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash .9s .35s ease-in-out forwards;
    animation: dash .9s .35s ease-in-out forwards;
  }
  &.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check .9s .35s ease-in-out forwards;
    animation: dash-check .9s .35s ease-in-out forwards;
  }
}
         
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
