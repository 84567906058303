.wrapper {
  padding: 2em;
}

.wrapper .buttonConfirm {
  width: 12em;
}

.wrapper .button {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}

.wrapper .type {
  padding: 1.5em;
}

input[type=checkbox],
input[type=radio] {
  transform: scale(1.25);
  margin-right: 0.75em !important;
}

input:disabled,
select:disabled {
  color:#999;
}

label {
  margin-top: 2px !important;
}

.wrapper .blue {
  color: #1f76dc;
}

.wrapper .disabled {
  color:#999;
}

.wrapper .storeAddress {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1em;
}

.wrapper .buttonConfirm {
  transition: all .5s ease;
}

.wrapper .buttonConfirm > span {
  transition: all .3s ease-in-out;
}

.wrapper .errorMsg {
  color: red;
  font-style: italic;
  font-size: 0.9em;
  font-family: "proxima_novalight";
  font-weight: 600;
}

.wrapper .dot {
  border: 1.5px solid #212529;
  border-radius: 50%;
  height: 16px;
  width: 16px;
}

.wrapper .dot i {
  font-size: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3.5px;
}

.wrapper .newAddressRadio {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  column-gap: 0.75em;
}


@media only screen and (max-width: 650px) {
  .wrapper {
    overflow: auto;
    max-height: 100%;
  }
}
