*,
*::before,
*::after {
  box-sizing: border-box;
}

.mainContainer {
  width: 100%;
}

.ordersTable {
  border-collapse: collapse;
  margin: 1em;
  box-shadow: 0 2px 10px rgb(72 73 73 / 20%);
  border-radius: 6px;
  background: #ffffff;
}

.ordersTable td {
  text-align: left;
  height: 4em;
  padding: 0.5em 1em;
  color: #5e5d5e;
}

.ordersTable th {
  padding: 1em;
  text-align: left;
  text-transform: uppercase;
}

.ordersTable th, td {
  border-bottom: 1px solid lightgrey;
}

.ordersTable tr:hover {
  background-color: #f5f5f5;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 3em 0 0.5em;
  padding: 0 3em;
}

.headerText {
  font-size: 1.75em;
  font-weight: bold;
  text-transform: uppercase;
  color: #5e5d5e;
}

.headerText i {
  margin: 0 0.75em;
}

.headerText span {
  color: #1f1f28;
  font-size: 1.15em;
}

.unopened {
  margin-left: 1em;
  color: red;
  font-size: 0.8em;
}

.ordersTable td:nth-child(1) {
  white-space: nowrap;
}

.shippedBtn > div {
  max-width: 250px !important;
  width: 100%;
  float: right;
}

.skeletonAnimation {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD;
  width: 100%;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

@media screen and (max-width: 850px) {
  .skeletonAnimation { 
    margin: 0 1.5em;
    height: 1.25em;
  }

  table.ordersTable .productsList {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  table.ordersTable .tdHeight {
    height: auto;
  }

  table.ordersTable {
    background: transparent;
  }

  table.ordersTable thead {
    display: none;
  }

  table.ordersTable tr {
    padding: 5px;
    display: block;
    margin: 0 0 1em;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }

  table.ordersTable tr:last-child {
    margin: 0 0 0;
  }

  table.ordersTable td {
    border-bottom: 1px dotted #ccc;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  table.ordersTable td:last-child {
    border-bottom: 0;
  }

  table.ordersTable td.tdBtn {
    justify-content: center;
    font-family: "proxima_novabold";
  }

  table.ordersTable td:last-child div {
    width: 15em;
  }

  table.ordersTable td:first-child {
    font-family: "proxima_novabold";
  }

  table.ordersTable td:before {
    content: attr(data-label);
    font-weight: bold;
    font-family: "proxima_novabold";
  }

  table.ordersTable td {
    font-family: "proxima_novalight";
  }
}

@media only screen and (max-width: 500px) {
  .header {
      margin: 3em 0 1.5em;
      padding: 0 1em;
      display: flex;
      justify-content: center;
      flex-direction: row;
      line-height: 1.75em;
  }
}
