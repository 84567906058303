.MainNav {
  min-width: 23em;
  display: flex;
  flex-direction: column;
  background-color: #1f1f28;
  color: #bcbdc0;
  padding: 0 1em;
  height: 100%;
}

.divider {
  border-bottom: 1px solid gray;
  margin: 1em 0;
}

.MainNavUserWelcome {
  font-weight: bold;
  font-size: 1.2em;
  padding: 0.9em 0 0.5em;
  display: flex;
  flex-direction: column;
  column-gap: 1em;
  z-index: 9999;
  border-bottom: 1px solid gray;
}

.MainNavUserWelcome > span {
  color: #1f76dc;
  cursor: pointer;
  font-size: 0.8em;
  padding-top: 3px;
}

.MainNavHeader {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0.8em 0em 1em 0;
}

.MainNavHeader i {
  font-size: 0.8em;
  margin-right: 1em;
}

.sideNavItemsActive {
  margin: 0em 0em 0.5em 2.25em;
  text-transform: uppercase;
}

.sideNavItems {
  margin: 0em 0em 0.5em 2.25em;
  text-transform: uppercase;
}

.sideNavItemsActivePrices {
  margin: 0em 0em 0.8em 4.25em;
  text-transform: uppercase;
}

.sideNavItemsPrices {
  margin: 0em 0em 0.8em 4.25em;
  text-transform: uppercase;
}

.noneDecoration {
  text-decoration: none !important;
}

.searchContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 1em;
}

.searchContainer > input {
  display: inline-block;
  padding: 7px 10px;
  line-height: 135%;
  margin-bottom: 1em;
}

.MainNavUserWelcomeMobile {
  display: flex;
  flex-direction: row;
  background-color: #1f1f28;
  color: #bcbdc0;
  padding: 1em;
  column-gap: 1.5em;
  align-items: center;
  z-index: 999;
}

.MainNavUserWelcomeMobile {
  display: none;
}

.MainNavUserWelcome {
  display: flex;
}

.navIcons > i:nth-child(2) {
  display: none;
}

.sideNavItems span:hover,
.sideNavItemsPrices span:hover {
  filter:brightness(120%);
}

//Smaller than 850px
@media only screen and (max-width: 850px) {
  .MainNavUserWelcomeMobile {
    display: flex;
  }

  .MainNavUserWelcome {
    display: none;
  }

  .itemsMenu > nav {
    height: 100vh;
    position: absolute;
    top: 49px;
    left: 0;
  }

  .navIcons > i:nth-child(2) {
    display: block;
    position: absolute;
    right: 1em;
    top: 0.7em;
  }
}