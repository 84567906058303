.button {
    cursor: pointer;
    align-items: center;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    padding: 0.8em;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
    font-size: 0.95em;
    justify-content: center;
    border-radius: 0.25em;
}

.button:hover {
    filter: brightness(85%);
}

.noStyle {
    cursor: pointer;
    color: #ccc;
}

.noStyle.active {
    font-weight: bold;
    color: #fff;
}

.buttonLabel {
    padding-left: 0.35em;
}

.buttonIcon {
    font-size: 1.3em;
}

.largeButtonIcon {
    font-size: 1.9em;
}

.emptyBadge {
    margin-left: 2.5em;
}

.badgeCls {
    display: inline-block;
    color: #fff;
    text-align: center;
    line-height: 0px;
    border-radius: 50%;
    font-size: 0.8em;
    min-width: 30px;
    min-height: 30px;
    background-color: red;
    margin-right: 5px;
}

.badgeCls span {
    display: inline-block;
    padding-top: 50%;
    padding-bottom: 50%;
    margin-right: 1px;
}

/* Gray icon with white shadow, good for all backgrounds */
.button-icon.gray-with-white-shadow {
    color: #606060;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

.whiteTheme {
    background-color: #fff;
    color: #0657a8;
    //padding: 0.5333em;;
}

.greenTheme {
    background-color: green;
    color: white;
    //padding: 0.5333em;;
}

.blueTheme {
    background-color: #1f76dc;
    color: #fff;
}

.grayTheme {
    background-color:#6c757d;
    color: #fff;
}

.redTheme {
    background-color: #dc3545;
    color: #fff;
}

.flexColumn {
    flex-direction: column;
}
.flexColumn .buttonLabel {
    padding-left: 0;
    padding-top: 0.3em;
}

.button.disabled {
    cursor: default;
    opacity: 0.5;
}
