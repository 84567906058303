.container {
  display: flex;
  flex-direction: column;
}

.container .body {
  padding: 1em;
  overflow: auto;
  text-align: center;
  font-size: 16px;
}

.container .twoButtons {
  display: flex;
  flex-direction: row;
  padding: 1em;
  column-gap: 1.3em;
  justify-content: center;
  align-items: center;
}

.container .twoButtons button {
  flex: 1;
}

.container .oneButton {
  display: flex;
  flex-direction: row;
  padding: 1em;
  justify-content: center;
  align-items: center;
}

.container .cancelButton {
  margin-right: 5px;
  min-width: 95px;
  max-width: 125px;
  width: 100%;
}

.container .confirmButton {
  margin-left: 5px;
  min-width: 95px;
  max-width: 125px;
  width: 100%;
}

.container .confirmButtonWithIcon {
  margin-left: 5px;
  min-width: 95px;
  max-width: 125px;
  width: 100%;
}

.container .cancelButton span:nth-child(2),
.container .confirmButton span:nth-child(2),
.container .confirmButtonWithIcon span:nth-child(2){
  padding-right: 0.35em;
}

.container .cancelButton span:nth-child(1),
.container .confirmButton span:nth-child(1) {
  display: none;
}

.container .urlLink {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}

@media only screen and (max-width: 650px) {
  .container .body {
    overflow-wrap: anywhere;
    white-space: pre-line;
    max-width: 100%;
    padding: 1.5em;
  }
}