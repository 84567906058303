.wrapper {
  padding: 2em;
}

.wrapper .buttonConfirm {
  width: 12em;
}

.wrapper .button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.wrapper .error {
  font-family: "proxima_novalight";
  color: #ff554c;
  font-size: 12px;
}