.modal-wrap {
	height: 100vh;
	left: 0;
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 0;
	width: 100vw;
	transition: all ease 1s;
	animation: fadein .5s;
}

.modal-overlay {
	background-color: #000;
	cursor: pointer;
	/* Required so onClick event fires */
	height: 100vh;
	left: 0;
	opacity: 0.7;
	overflow: hidden;
	pointer-events: all;
	position: absolute;
	right: 0;
	top: 0;
	width: 100vw;
}

.modal {
	background-color: white;
	box-shadow: 0 0 5px #575757;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: absolute;
	pointer-events: all;
}

.modal-left,
.modal-right {
	bottom: 0;
	height: 100vh;
	max-width: 85%;
	top: 0;
}

.modal-left {
	left: 0;
}

.modal-right {
	right: 0;
}

.modal-center {
	height: auto;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	width: 375px;
}

.modal-md {
	height: auto;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	width: 32em;
}

.modal-lg {
	height: auto;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	width: 38em;
}

.modal>.body {
	flex: 1;
	overflow: hidden;
}

.modal>.header {
	align-items: center;
	background-color: #fff;
	border-color: #fff;
	flex: none;
	display: flex;
}

.modal>.header>.title {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	flex: 1;
	font-size: 1.2em;
	padding: 0.5em;
}

.modal>.header>.close-tool {
	background-color: #fff;
	border: 0 none;
	color: #575757;
	font-size: 1.25em;
	padding: 0.5em;
	cursor: pointer;
}

/* modal show left
*********************************************/

.modal-show-left {
	animation-name: modalShowLeft;
	-webkit-animation-name: modalShowLeft;

	animation-duration: 0.3s;
	-webkit-animation-duration: 0.3s;

	animation-timing-function: ease-in-out;
	-webkit-animation-timing-function: ease-in-out;

	visibility: visible !important;
}

@keyframes modalShowLeft {
	0% {
		transform: translateX(-150%);
		/* Why 150% - jfrye */
	}

	100% {
		transform: translateX(0%);
	}
}

@-webkit-keyframes modalShowLeft {
	0% {
		-webkit-transform: translateX(-150%);
		/* Why 150% - jfrye */
	}

	100% {
		-webkit-transform: translateX(0%);
	}
}

/* modal show right
*********************************************/

.modal-show-right {
	animation-name: modalShowRight;
	-webkit-animation-name: modalShowRight;

	animation-duration: 0.5s;
	-webkit-animation-duration: 0.5s;

	animation-timing-function: ease-in-out;
	-webkit-animation-timing-function: ease-in-out;

	visibility: visible !important;
}

@keyframes modalShowRight {
	0% {
		transform: translateX(150%);
		/* Why 150% - jfrye */
	}

	100% {
		transform: translateX(0%);
	}
}

@-webkit-keyframes modalShowRight {
	0% {
		-webkit-transform: translateX(150%);
		/* Why 150% - jfrye */
	}

	100% {
		-webkit-transform: translateX(0%);
	}
}

@keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Smaller than 500 */
@media only screen and (max-width: 500px) {

	.modal-left,
	.modal-right {
		border-radius: 0 !important;
		max-width: none;
		width: 100%;
	}
}

/* Smaller than 650 */
@media only screen and (max-width: 650px) {
	.modal-lg,
	.modal-md {
		border-radius: 0 !important;
		max-width: none;
		width: 100%;
		height: 100%;
		z-index: 1000;
	}
}