.linksWrapper {
  display: flex;
  flex-direction: row;
  column-gap: 0.5em;
  align-items: center;
  color: #5e5d5e;
  cursor: pointer;
}

.linksWrapper a {
  padding-left: 0.5em;
  margin-top: 0.25em;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}