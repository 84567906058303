.pagination {
  padding: 8px 0px;
  width: 100%;
  font-weight: 500;
  text-align: left;
  font-size: 14px;
  color: #2c3e50;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
}

.button {
  border: none;
  padding: 7px 14px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 4px;
  margin-left: 4px;
  background: #FFFFFF;
}

.dotsButton {
  pointer-events: none;
  cursor: default;
  border: none;
  padding: 7px 14px;
  margin-right: 4px;
  margin-left: 4px;
  background: transparent;
}

.dotsAndNumberWrapper {
  display: flex;
  flex-direction: row;
}

.activeButton {
  color: #fff;
  background: #1f76dc;
}

.inactiveButton {
  color: #2c3e50;
}

.noClick {
  cursor:default;
}

@media only screen and (max-width: 500px) { 
  .button {
    padding: 7px 7px;
    font-size: 1.25em;
  }
}
